<c-tabs [activeItemKey]="tabs.recap" (activeItemKeyChange)="setTab($any($event))">
  <c-tabs-list variant="underline-border">
    <button cTab [itemKey]="tabs.recap">{{tabs.recap | translate}}</button>
    <button cTab [itemKey]="tabs.viewrequest">{{tabs.viewrequest | translate}}</button>
    <button cTab [itemKey]="tabs.viewsituation">{{tabs.viewsituation | translate}}</button>
    <button cTab [itemKey]="tabs.whitelists" *ngIf="userSituation">{{tabs.whitelists | translate}}</button>
    <button cTab [itemKey]="tabs.viewCardTransactions"  *ngIf="userSituation">{{tabs.viewCardTransactions| translate}}</button>
  </c-tabs-list>
  <c-tabs-content>
    <c-tab-panel class="p-3" [transition]="false"[itemKey]="tabs.recap">
      <c-progress variant="striped" [value]="100" [animated]="true" class="mb-3" color="primary" *ngIf="loading"></c-progress>
      <rm-refund-recap
        *ngIf="!loading"
        [request]="$any(targetObject)"
        (requestEmitter)="updateRequest($event)"
        (closeEmitter)="close($event)"
      ></rm-refund-recap>
    </c-tab-panel>
    <c-tab-panel class="p-3" [transition]="false"[itemKey]="tabs.viewrequest">
      <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary" *ngIf="loading"></c-progress>
      <strong *ngIf="targetObject?.requestAutomation">
        Ceci est une demande de remboursement automatique à valider
      </strong>
      <strong *ngIf="targetObject.requestInstallment" class="orange">
        Ceci est une demande de remboursement fractionnée à valider
      </strong>
      <rm-reactive-forms
        *ngIf="objectDef && targetObject && !loading"
        [objectDefinition]="objectDef"
        [objectToUpdate]="targetObject"
        (saveEmitter)="updateRequest($event)"
        [showSaveTop]="true"
      >
      </rm-reactive-forms>
    </c-tab-panel>
    <c-tab-panel class="p-3" [transition]="false"[itemKey]="tabs.viewsituation">
      <c-progress variant="striped" [value]="100" animated class="mb-3" color="primary" *ngIf="loading"></c-progress>
      <rm-user-situation
        *ngIf="userSituation"
        [situation]="userSituation"
        [requestInHand]="targetObject"
        [email]="targetObject.email"
        (closeEmitter)="close($event)"
        (updatedRequestRefundEmitter)="getStatus()"
      >
      </rm-user-situation>
    </c-tab-panel>
    <c-tab-panel class="p-3" [transition]="false"[itemKey]="tabs.whitelists" *ngIf="userSituation">
      <div class="row" *ngIf="whiteLists?.length">
        <div class="col-12">
          <h2>
            {{'whitelists' | translate}}
          </h2>
        </div>
        <div class="col-12" *ngFor="let item of whiteLists">
          <h5>{{item.name}}</h5>
          <p [innerHTML]="item.customRules"></p>
        </div>
      </div>
    </c-tab-panel>
    <c-tab-panel class="p-3" [transition]="false"[itemKey]="tabs.viewCardTransactions" *ngIf="userSituation">
      <rm-transactions-viewer
        *ngIf="targetObject"
        [agentRef]="targetObject.agentRef"
        [companyRef]="targetObject.companyRef"
        [email]="targetObject.email"
      >
      </rm-transactions-viewer>
    </c-tab-panel>
  </c-tabs-content>
</c-tabs>

<div class="tab-content">

  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.whitelists}">
 
  </div>
  <div class="tab-pane" role="tabpanel" [ngClass]="{'active': currentTab === tabs.viewCardTransactions}">
    
  </div>
</div>
