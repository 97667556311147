import { Component, OnInit } from '@angular/core';
import { BeneficiaryGroup, fileStoringWorkflows, FormDefinition, ObjectDefList } from '@roadmate/roadmate-common';
import { RMToasterService, FireStoreService, ModalService } from '@rm-services';
import { AbstractEntryComponent } from '../abstract.entry-component';
import { ReactiveFormsComponent } from '@rm-modules/reactive-forms/reactive-forms.component';
import { NgIf } from '@angular/common';
import { ColComponent, RowComponent } from '@coreui/angular';
import { TranslateModule } from '@ngx-translate/core';



@Component({
  selector: 'rm-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsComponent, NgIf, TranslateModule, ColComponent, RowComponent
  ]
})
export class AddGroupComponent extends AbstractEntryComponent<any> implements OnInit {
  public groupDef: FormDefinition;
  public loading = false;
  public workflows = fileStoringWorkflows;
  public currentGroup: BeneficiaryGroup | null;
  public readonly = false;
  override set data(value: any) {
    if (!value.group) {
      this.currentGroup = null;
    } else {
      this.currentGroup = value.group;
      this.readonly = true;
    }
  }
  constructor(
    modalService: ModalService,
    private fs: FireStoreService,
    private toast: RMToasterService
  ) {
    super(modalService);
  }

  public ngOnInit() {
    const groupDef = this.fs.getObjectDefinitionByRef(ObjectDefList.BeneficiaryGroup);
    if (!this.currentGroup) {
      const {address1, postcode, city} = this.fs.currentCompany;
      groupDef['workAddress'].defaultValue = `${address1}, ${postcode} ${city}`;
      groupDef['roadmateProduct'].readonly = false;
    }
    this.groupDef = groupDef;
  }

  public async save(item: BeneficiaryGroup) {
    this.loading = true;
    try {
      const agentRef = this.fs.currentAgent.ref;
      const companyRef = this.fs.currentCompany.ref;
      if (!agentRef || !companyRef) {
        throw new Error('Company Or Agent not selected');
      }
      const existingGroups = await this.fs.getGroups(agentRef, companyRef);
      if (existingGroups?.length && existingGroups.some(group => group.name.toLocaleUpperCase() === item.name.toLocaleUpperCase())) {
        await this.toast.showTranslatedWarning('GROUP_ALREADY_EXISTS');
        return;
      }
      const ref = await this.fs.saveGroup(agentRef, companyRef, item);
      item.ref = ref;
      this.currentGroup = item;
      await this.toast.showTranslatedSuccess('GROUP_CREATED_SUCCESS');
      return;
    } catch (e) {
      await this.toast.showTranslatedError('GROUP_CREATE_FAIL');
    } finally {
      this.loading = false;
      this.close(true);
    }
  }

}
